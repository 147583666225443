<template>
  <v-dialog
      v-model="internalActiveState"
      v-bind="config"
      v-on="$listeners"
      @keydown.esc="internalActiveState = false"
  >
    <v-card>
      <v-card-title class="text-h6">
        <slot name="header" />
      </v-card-title>

      <v-card-text class="text-body-1">
        <slot name="body" />
      </v-card-text>

      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Dialog',
  computed: {
    internalActiveState: {
      get () {
        return _.get(this.$attrs, 'active', false)
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    config () {
      const defaultProps = {
        active: false,
        width: '600',
        scrollable: true,
        contentClass: 'v-dialog--common'
      }

      return _.assign({}, defaultProps, this.$attrs)
    }
  }
}
</script>
