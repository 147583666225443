<template>
  <v-card>
    <v-card-title>
      <v-row
          no-gutters
          justify="space-between"
          align="start"
      >
        <v-col cols="auto">
          Linked Applications (Relying Parties)
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
          must-sort
          class="elevation-1 pa-0 text--dark"
          :headers="headers"
          :options.sync="options"
          :items="apps"
          :server-items-length="apps.length"
          :footer-props="{ itemsPerPageOptions: rowsPerPageItems }"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default
{
  name: 'ApplicationsTable',
  props: {
    apps: {
      required: true,
      type: Array,
      default: []
    }
  },
  data () {
    return {
      loaded: false,
      options: {
        rowsPerPage: 10
      },
      rowsPerPageItems: [10, 15, 25],
      headers: [
        {
          text: 'Title',
          align: 'left',
          sortable: false,
          value: 'name',
          width: '20%'
        },
        {
          text: 'Application ID',
          align: 'left',
          sortable: false,
          value: 'uid',
          width: '20%'
        },
        {
          text: 'Permitted Scopes',
          align: 'left',
          sortable: false,
          value: 'scopes',
          width: '15%'
        },
        {
          text: 'Application URI',
          value: 'application_uri',
          align: 'left',
          sortable: false,
          width: '20%'
        },
        {
          text: 'User Count',
          value: 'user_count',
          align: 'center',
          sortable: false,
          width: '5%'
        }
      ]
    }
  }
}
</script>