<template>
  <v-card>
    <v-card-title>
      <v-row
          no-gutters
          justify="space-between"
          align="center"
      >
        <v-col cols="auto">
          Existing Users
        </v-col>
        <v-col cols="4">
          <v-text-field
              append-icon="icon-mag-glass"
              placeholder="Search Users"
              clearable
              @click:clear="searchUsers(null)"
              @keyup="searchUsers($event.target.value)"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
          must-sort
          class="elevation-1 pa-0 text--dark"
          :headers="headers"
          :options.sync="options"
          :items="formattedUsers"
          :loading="loading"
          :server-items-length="totalResults"
          :footer-props="{ itemsPerPageOptions: rowsPerPageItems }"
      >
        <template #item.become="{ item }">
          <v-btn
              class="v-btn--secondary"
              @click="confirmBecomeUser(item)"
          >
            Become User
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <ConfirmDialog
        v-if="showBecomeUserDialog"
        :active="showBecomeUserDialog"
        :header="`Log in as ${selectedUser.email}?`"
        :content="`Are you sure you want to log in as '${selectedUser.email}'?
          This will log you out of your admin acount.`"
        @close="showBecomeUserDialog = false, selectedUser = null"
        @confirm="becomeUser(selectedUser.id)"
    />
  </v-card>
</template>

<script>
import { debounce } from 'lodash'
import ConfirmDialog from "./shared/ConfirmDialog";
export default
{
  name: 'UsersTable',
  components: {ConfirmDialog},
  data () {
    return {
      loaded: false,
      loading: false,
      paginatedUsers: [],
      totalResults: null,
      search: null,
      options: {
        itemsPerPage: 10,
        sortBy: ['email'],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 15, 25],
      headers: [
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'email',
          width: '20%'
        },
        {
          text: 'First Name',
          align: 'left',
          sortable: true,
          value: 'first_name',
          width: '20%'
        },
        {
          text: 'Last Name',
          align: 'left',
          sortable: true,
          value: 'last_name',
          width: '20%'
        },
        {
          text: 'Applications',
          align: 'left',
          sortable: false,
          value: 'application_names',
          width: '20%'
        },
        {
          text: 'Become User',
          align: 'center',
          sortable: false,
          value: 'become',
          width: '20%'
        },
        {
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '10%'
        }
      ],
      showBecomeUserDialog: false,
      selectedUser: null
    }
  },
  computed: {
    formattedUsers () {
      return this.paginatedUsers.map((user) => ({
        ...user,
        application_names: user.applications.map((app) => (app.name)).join(', ')
      }))
    }
  },
  watch: {
    options: {
      handler () {
        this.paginateUsers()
      },
      deep: true
    }
  },
  methods: {
    paginateUsers () {
      const sortBy = this.options.sortBy[0] || 'email'
      const sortDesc = this.options.sortDesc[0] || false
      const params = {
        start: ((this.options.page - 1) * this.options.itemsPerPage),
        length: this.options.itemsPerPage,
        search: this.search,
        order_col: sortBy,
        order_dir: sortDesc ? 'DESC' : 'ASC'
      }
      this.loading = true
      this.$http
          .post('/users/data.json', params)
          .then((response) => {
            this.paginatedUsers = response.data.records
            this.totalResults = response.data.recordsFiltered
            this.loading = false
          }).finally(() => {
        this.loading = false
      })
    },
    confirmBecomeUser(user) {
      this.selectedUser = user
      this.showBecomeUserDialog = true
    },
    searchUsers: debounce(function (searchTerm) {
      this.search = searchTerm
      this.options.page = 1
      this.paginateUsers()
    }, 400),
    becomeUser() {
      window.location.href = `/become_user/${this.selectedUser.id}`
    }
  }
}
</script>