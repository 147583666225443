<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'MainContent'
}
</script>
