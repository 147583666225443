<template>
  <v-app-bar
      app
      class="effect--dark"
      color="secondary"
  >
    <v-app-bar-title>
      Authentication Settings
    </v-app-bar-title>
    <template v-slot:extension>
      <v-tabs
        v-model="selectedTab"
        align-with-title
      >
        <v-tab
            v-for="(option, idx) in tabOptions"
            :key="idx"
            :href="option.href">
          {{ option.title }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  data () {
    return {
      selectedTab: '/',
      tabOptions: [{
          title: 'Profile',
          href: '/profile'
        },
        {
          title: 'Users',
          href: '/users'
        },
        {
          title: 'Applications',
          href: '/oauth-applications'
        }
      ]
    }
  },
  created () {
    this.selectedTab = window.location.pathname
  }
}
</script>

<style lang="scss" scoped>
.v-tabs {
  ::v-deep {
    .v-tabs-slider-wrapper {
      transition: none;
    }
  }
}
</style>
