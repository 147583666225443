<template>
  <v-app class="auth-app">
    <slot></slot>
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>