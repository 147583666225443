/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue/dist/vue.esm'
import Vuetify from 'vuetify'
import axios from 'axios'
import 'vuetify/dist/vuetify.min.css'
import '../styles/main.scss'
import App from '../App.vue'
import LoginCard from "../components/LoginCard";
import LoginLayout from "../components/layouts/LoginLayout";
import MainLayout from "../components/layouts/MainLayout";
import AppBar from "../components/AppBar";
import MainContent from "../components/MainContent";
import UsersTable from "../components/UsersTable";
import ApplicationsTable from "../components/ApplicationsTable";
import Dialog from "../components/shared/Dialog";
import ConfirmDialog from "../components/shared/ConfirmDialog";

Vue.prototype.$http = axios.create()
//Fix CSRF
Vue.prototype.$http.interceptors.request.use((config) => {
  let meta = document.querySelector('meta[name="csrf-token"]')
  if(!meta) { return config }
  let token = meta.getAttribute('content')
  if(!token) { return config }
  config.headers['X-CSRF-TOKEN'] = token
  return config
})
Vue.use(Vuetify)
//Component Registrations are uppercase here
//as rails lowercases all tags when rendering erb for some reason.
//Therefore any caps in a rails partial are removed.
Vue.component('appbar', AppBar)
Vue.component('logincard', LoginCard)
Vue.component('mainlayout', MainLayout)
Vue.component('maincontent', MainContent)
Vue.component('userstable', UsersTable)
Vue.component('confirmdialog', ConfirmDialog)
Vue.component('dialog', Dialog)
Vue.component('userstable', UsersTable)
Vue.component('applicationstable', ApplicationsTable)
Vue.component('loginlayout', LoginLayout)
Vue.component('app', App)


document.addEventListener('DOMContentLoaded', () => {
  let opts = {
    theme: {
      dark:false,
      themes: {
        light: {
          primary: '#5BADCA',
          secondary: '#263238',
          accent: '#49A696',
          info: '#192A3A'
        }
      }
    }
  }

  const app = new Vue({
    vuetify: new Vuetify(opts)
  }).$mount('[data-mount="vue"]')
})


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
