<template>
  <v-card
      flat
  >
    <v-card-title>
      <slot name="header" />
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container class="py-0">
        <slot name="content" />
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-2 pt-0">
      <v-container class="pa-0 ma-0" >
        <slot name="controls" />
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'LoginCard'
}
</script>

<style lang="scss" scoped>
.v-card {
  position: absolute;
  top:75px;
  right:140px;
  width:500px;
  background-color: white;
  box-sizing: border-box;

  &__title {
    max-height:160px;
    background-color:white;
  }
}
</style>
