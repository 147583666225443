<template>
  <Dialog
      :active="internalActiveState"
      :title="header"
      @close="internalActiveState = false"
  >
    <template #header>
      {{ header }}
    </template>

    <template #body>
      <span
          v-if="content"
          class="text-body-1 text--dark"
      >
        {{ content }}
      </span>
      <slot v-else />
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
          class="v-btn--tertiary"
          depressed
          test-hook="cancel-btn"
          @click="internalActiveState = false"
      >
        Close
      </v-btn>
      <v-btn
          ref="buttonFocus"
          class="v-btn--primary"
          depressed
          :loading="loading"
          test-hook="confirm-btn"
          nw-hook="confirm-dialog-confirm-btn"
          @click="confirm()"
      >
        Confirm
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import _ from 'lodash'
import Dialog from './Dialog.vue'
export default {
  name: 'ConfirmDialog',
  components: {
    Dialog
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    header: {
      type: String,
      required: false,
      default: 'Confirm'
    },
    content: {
      type: String,
      required: false,
      default: null
    },
    closeOnConfirm: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    internalActiveState: {
      get () {
        return this.active
      },
      set (value) {
        if (!value) {
          this.$emit('close', null)
        }
      }
    }
  },
  mounted () {
    setTimeout(() => {
      _.invoke(this.$refs, 'buttonFocus.$el.focus')
    })
  },
  methods: {
    confirm () {
      this.$emit('confirm', null)
      if (this.closeOnConfirm) {
        this.internalActiveState = false
      } else {
        this.loading = true
      }
    }
  }
}
</script>
