<template>
  <v-main app>
    <slot></slot>
  </v-main>
</template>
<script>
  export default {
    name: 'LoginLayout'
  }
</script>
<style scoped>
.v-main {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/login_bg.jpg')
}
</style>
